<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'partners'"
          :class="active === 'partners' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("crm.partners") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'teams'"
          :class="active === 'teams' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("crm.teamList") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'customers'"
          :class="active === 'customers' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("crm.customers") }}
        </button>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10">
      <h2>
{{ $t("crm.crm") }}
</h2>
      <custom-rows v-model:type="active" />
    </div>
  </div>
</template>

<script>
import CustomRows from "@/components/settings/products/CustomRows";

export default {
  name: "Crm",
  components: { CustomRows },
  data() {
    return {
      active: "partners",
    };
  },
};
</script>
